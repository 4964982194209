$(document).ready(function () {

    $(document).on("scroll", function () {

        var scrollPos = $(document).scrollTop();
        $('#navbar a').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("href"));
            if (refElement.position().top - $('nav.navbar').height() <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('#navbar ul li').removeClass("active");
                currLink.parent('li').addClass("active");
            }
            else {
                currLink.removeClass("active");
            }
        });

    });

    $('a[href*="#"]').not('[href="#"]').click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
            }
        }
    });

});
