/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('./SmoothScroll');


require('jquery-validation');

// function onSubmit(token) {
//     document.getElementById("contact-us-form").submit();
// }
$(document).ready(function () {


    // document.getElementById('contact-us-form').onsubmit = function (event) {
    //     let contactForm = this;
    //     event.preventDefault();
    //
    //     grecaptcha.ready(() => {
    //         grecaptcha.execute(process.env.MIX_RECAPTCHA_SITE_KEY, {action: 'contact'}).then((token) => {
    //             let input = document.createElement("input");
    //             input.type = "hidden";
    //             input.name = "g-recaptcha-response";
    //             input.value = token.toString();
    //             console.log('THIS', this);
    //             this.appendChild(input);
    //
    //             this.submit();
    //         });
    //     });
    // };
    let validation = $('#contact-us-form').validate({
        rules: {
            name: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            message: {
                required: true
            }
        }
    });

    document.getElementById('contact-us-form').addEventListener('submit', function (event) {
        event.preventDefault();
        grecaptcha.ready(() => {
            grecaptcha.execute(process.env.MIX_RECAPTCHA_SITE_KEY, {action: 'contact'}).then((token) => {
                let input = document.createElement("input");
                input.type = "hidden";
                input.name = "g-recaptcha-response";
                input.value = token.toString();
                this.appendChild(input);


                if (validation.valid()) {
                    this.submit();

                }

            });
        });
    });


});
